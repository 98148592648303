
































































import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class SuccessModalComponent extends Vue {
  public showing = false;

  public title = 'Hello';

  public successDescription = 'Description text';

  public closeText = 'close';
}
