
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BaseComponent from '../BaseComponent.vue';

@Component({})
export default class ObjectList extends BaseComponent {
  @Prop() title!: string;

  @Prop() routeNew!: string;

  clicked() {
    this.$emit('add-click');
  }
}
