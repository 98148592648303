
import Component from 'vue-class-component';
import TableRowSkeleton from '@/components/skeletons/TableRowSkeletons.vue';
import { Prop } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import BaseComponent from '@/components/BaseComponent.vue';
import ObjectList from '@/components/lists/ObjectList.vue';
import memberSchema from '@/modules/organisation/schema/member.schema';
import services from '@/services';
import VsDivider from '@/components/vx-divider/vsDivider.vue';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import ToastificationContent from '@/app/components/toastification/ToastificationContent.vue';
import { POSITION } from 'vue-toastification';
import { Loading, Loader } from '../../auth/store/auth.service';
import OrganisationMemberListSvg from './OrganisationMemberListSvg.vue';
import { ITenantUserDto, TenantUserJoined, TenantUserRole, TenantUserStatus } from '../../../app/entities/system/account/ITenantDTO';
import { IMemberDto, ITenantDto } from '../../../app/entities';
import Role from '@/app/entities/role';
import service from '../../../services/app-http-client';
import userModule from '../../../store/modules/user/user.module';
import Routes from '@/shared/constants/routes';

export enum MemberRoles {
  Owner = 'Owner',
  Accommodation = 'Accommodation',
}
@Component({
  components: { ObjectList, TableRowSkeleton, VsDivider, OrganisationMemberListSvg },
  computed: {
    ...mapState('tenant', ['tenant']),
    ...mapGetters('tenant', ['accommodationName']),
  },
})
export default class TenantMembersComponent extends BaseComponent {
  @Prop()
  public propTenantApiKey!: string;

  @Prop({ default: 'Users' }) readonly title!: string;

  tenant!: ITenantDto;

  schema = memberSchema;

  services = services;

  tenantApiKey = '';

  items: any[] = [];

  tenantJoinSettings: any = { name: 'lol' };

  organisation: ITenantDto;

  addMember = false;

  enableLink = true;

  enablePublicUrl = true;

  requireAcceptance = true;

  copiedUrlLink = true;

  memberCount!: number;

  isOwnerOrAdmin = true;

  maxNumberOfUsers = 2;

  role!: TenantUserRole;

  loading = false;

  member = {
    email: '',
    role: 'Member',
    inviteToken: '',
    accommodationId: '',
    key: tenantModule.tenant.id,
  };

  roles = "{first: 'First', second: 'Second', third: 'Third', fourth: 'Fourth'}";

  validation: any = {};

  mounted(): void {
    this.loading = true;
    this.reload();
  }

  get invited() {
    return tenantModule.tenant.subscription?.userInvites || 0;
  }

  get maxNumberOfUsersReached(): boolean {
    if (this.invited === 0) {
      return true;
    }
    return false;
  }

  get changeInvitationText() {
    let invitationWording = '';
    if (this.invited === 0 || this.invited > 1) {
      invitationWording = 'invitations';
    } else if (this.invited === 1) {
      invitationWording = 'invitation';
    }
    return invitationWording;
  }

  @Loading
  async resendInvite(item: IMemberDto) {
    const payload = item.invitation;
    const oldToken = item.invitation.inviteToken;

    const inviteToken = await this.$msal.getInviteToken({
      login_hint: payload.email,
      accommodationId: item.accommodationId,
      key: tenantModule.tenant.id,
      role: item.role,
    });

    const response = await service.put(`members/invitation/${oldToken}`, inviteToken);

    return response;
  }

  checkMember() {
    if (!this.validation.hasErrors && this.member.accommodationId) {
      if (this.maxNumberOfUsersReached) {
        return new Promise((resolve, _reject) => {
          resolve(true);
          return this.$swal
            .fire({
              title: 'Upgrade plan',
              text: 'Please upgrade your plan to add more users to your Check-in Buddy account, or feel free to contact our support team if you have any questions',
              icon: 'warning',
              showConfirmButton: true,
              confirmButtonText: 'View Pricing',
              confirmButtonColor: '#E02424',
              cancelButtonText: 'Close',
              showCancelButton: true,
              customClass: {
                cancelButton:
                  'inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out',
              },
            })
            .then((showPricing) => {
              this.addMember = false;
              if (showPricing.isConfirmed) tenantModule.setShowPricing(true);
            });
        });
      }

      const check = this.memberList.some((member) => member.email === this.member.email);

      if (check) {
        return this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Duplicate email',
              text: 'This user has been added all ready',
              icon: 'concierge-bell',
              variant: 'danger',
            },
          },
          {
            position: POSITION.BOTTOM_RIGHT,
          },
        );
      } else {
        this.saveMember();
      }
    }
  }
  @Loading
  async saveMember(): Promise<any> {
    this.member.inviteToken = await this.$msal.getInviteToken({
      login_hint: this.member.email,
      key: this.member.key,
      accommodationId: this.member.accommodationId,
      role: this.member.role,
    });

    const res = await this.services.tenant.create(this.member);

    this.items.push(res);
    this.$swal('Member Invited', ' Member invited successfully', 'success');
    this.addMember = false;
    return res;
  }

  openMemberSidebar(): boolean | void {
    if (this.maxNumberOfUsersReached) {
      return this.$vs.notification({
        title: 'Max users',
        text: 'Please upgrade your account to add more users to your organisation.',
        color: 'primary',
        position: 'bottom-right',
        duration: 10000,
      });
    }
    this.addMember = !this.addMember;

    return this.addMember;
  }

  @Loading
  async deleteMember(id: string): Promise<void> {
    return this.services.tenant
      .delete(id)
      .then(async () => {
        await this.reload();
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  get accommodationList() {
    const accommodationList = tenantModule.accommodations.map((acc) => {
      return {
        value: acc.id,
        label: acc.name,
        id: acc.id,
      };
    });
    if (userModule.member[0].role.role === 'owner')
      accommodationList.push({
        value: 'all',
        label: 'All accommodations',
        id: 'all',
      });
    return accommodationList;
  }

  avatarText(user: any): any {
    if (user) {
      if (user.firstName && user.lastName) {
        if (user.firstName.length > 0 && user.lastName.length > 0) {
          return (user.firstName[0] + user.lastName[0]).toUpperCase();
        }
        if (user.firstName.length > 1) {
          return user.firstName.substring(0, 2).toUpperCase();
        }
        if (user.email.length > 1) {
          return user.email.substring(0, 2).toUpperCase();
        }
      } else {
        return user.email.substring(0, 2).toUpperCase();
      }
    }
    return '';
  }

  @Loading
  async reload() {
    this.services.tenant.findAll().then((members) => {
      this.items = members as any;
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    });
  }

  added(_data: ITenantUserDto) {
    this.reload();
  }

  saved(data: any) {
    const idx = this.items.findIndex((f) => f.id === data.id);
    //
    this.items[idx] = data;
  }

  deleted(data: { id: any }) {
    this.items = this.items.filter((f) => f.id !== data.id);
  }

  updateTenantJoinSettings(_reset = false) {
    // this.services.tenantUsers
    //   .updateInvitationSettings({
    //     enableLink: this.enableLink,
    //     resetLink: reset,
    //     enablePublicUrl: this.enablePublicUrl,
    //     requireAcceptance: this.requireAcceptance,
    //   })
    //   .then((response: any) => {
    //     this.tenantJoinSettings = response.data;
    //     this.enableLink = this.tenantJoinSettings.linkActive;
    //     this.enablePublicUrl = this.tenantJoinSettings.publicUrl;
    //     this.requireAcceptance = this.tenantJoinSettings.requireAcceptance;
    //   });
  }

  changeLinkSettings(_e: any) {
    this.updateTenantJoinSettings();
  }

  yesUpdateSubscription() {
    this.$router.push({ path: '/app/settings/organization/subscription' });
  }

  getUserRole(item: any) {
    return item.role.roleName;
  }

  getUserJoined(item: ITenantUserDto): any {
    if (item.status === TenantUserStatus.Active) {
      return this.$t(`settings.profile.joined.${TenantUserJoined[item.joined]}`);
    }
    return '';
  }

  getUserStatus(item: ITenantUserDto) {
    return TenantUserStatus[item.status];
  }

  getUserRoleClass(item: any): string | undefined {
    switch (item.role as Role) {
      case Role.OWNER:
        return 'bg-gray-800 text-gray-100';
      case Role.MEMBER:
        return 'bg-red-200';

      default:
        return 'bg-gray-100';
    }
  }

  goToBilling() {
    this.$router.push(Routes.ORGANISATION_BILLING);
  }

  getUserJoinedClass(item: ITenantUserDto): string | undefined {
    if (item.status === TenantUserStatus.Active) {
      switch (item.joined as TenantUserJoined) {
        case TenantUserJoined.Creator:
          return 'bg-gray-800 text-gray-100';
        case TenantUserJoined.JoinedByInvitation:
          return 'bg-blue-200';
        case TenantUserJoined.JoinedByLink:
          return 'bg-teal-200';
        case TenantUserJoined.JoinedByPublicUrl:
          return 'bg-red-200';
        default:
          return 'bg-gray-100';
      }
    }
    return '';
  }

  getUserStatusClass(item: ITenantUserDto) {
    switch (item.status as TenantUserStatus) {
      case TenantUserStatus.PendingInvitation:
        return 'bg-indigo-500 text-white';
      case TenantUserStatus.PendingAcceptance:
        return 'bg-teal-500 text-white';
      case TenantUserStatus.Active:
        return 'bg-teal-500 text-white';
      case TenantUserStatus.Inactive:
        return 'bg-red-500 text-white';
      default:
        return 'bg-gray-100';
    }
  }

  pendingAcceptance(item: ITenantUserDto) {
    return item.status === TenantUserStatus.PendingAcceptance;
  }

  get memberList() {
    if (!this.items) {
      return [];
    }
    return this.items.sort((x, y) => {
      return x.role > y.role ? -1 : 1;
    });
  }

  showPaymentModal() {
    tenantModule.setShowPricing(true);
  }
}
