
































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ConfirmModalComponent extends Vue {
  @Prop() public title = 'sclose';

  public closeText: any = 'string';

  @Prop() public isActive = false;

  //   mounted() {
  //     this.title = this.$t('shared.confirm').toString();
  //     this.closeText = this.$t('shared.close').toString();
  //   }

  public yes() {
    this.isActive = false;
    this.$emit('yes');
  }

  public no() {
    this.isActive = false;
    this.$emit('no');
  }
}
