enum Routes {
  BASE_ROUTE = '/',
  ERROR_404 = '/error-404',
  ERROR_500 = '/error-500',
  REDIRECT_ROUTE = '/redirect',
  USER_PROFILE_ROUTE = '/organisation/profile',
  GUEST_ROUTE = '/guest/',
  GUEST_BOOKING_ROUTE = '/guest/booking/:id',
  GUEST_CHECKIN_ROUTE = '/guest/check-in/:id',
  GUEST_DAILY_ROUTE = '/guest/daily-check/:id',
  ORGANISATION_DASHBOARD = '/organisation/dashboard',
  ORGANISATION_REGISTER = '/register',
  ORGANISATION_ACCOUNT = '/organisation/account/profile',
  ORGANISATION_BILLING = '/organisation/account/billing',
  ORGANISATION_SUBSCRIPTION = '/organisation/account/subscription',
  ORGANISATION_ACCOMMODATIONS = '/organisation/accommodations',
  ORGANISATION_BOOKING = '/organisation/booking',
  ORGANISATION_BOOKING_ADD = '/organisation/booking/add-booking',
  ORGANISATION_MEMBERS = '/organisation/members',
  GUEST_PROFILE_ROUTE = '/guest/profile',
  GUEST_DASHBOARD_ROUTE = '/guests',
  HOME_ROUTE = '/',
  HELP_CENTER = '/organisation/help-center',
  HELP_POST = '/organisation/help-center/help-post/:id',
}

export default Routes;
