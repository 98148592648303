import service from '@/services/app-http-client';
import { tenantModule } from '@/store/modules/tenant/tenant.module';

class TenantService {
  findAll() {
    return new Promise((resolve, reject) => {
      service
        .get(`members/${tenantModule.tenant.id}/all`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  findTenant() {
    return new Promise((resolve, reject) => {
      service
        .get('tenant')
        .then((response) => {
          if (response.status === 404) {
            resolve(false);
          }

          const { organisation } = response.data;

          // userModule.setUser(user as UserDto);
          tenantModule.setTenant(organisation);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  create(payLoad: any) {
    return new Promise((resolve, reject) => {
      service
        .post('members', payLoad)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  delete(payLoad: any): Promise<any> {
    return new Promise((resolve, reject) => {
      service
        .delete(`members/${payLoad}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  shortenUrl(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      service
        .post('https://cibs.me/api/link', { longUrl: payload })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default new TenantService();
