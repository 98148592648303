import { i18n } from '../../../shared/plugins/vue-i18n';

export default [
  {
    component: 'div',
    children: [
      {
        type: 'email',
        name: 'email',
        label: i18n.t('models.user.email'),
        placeholder: i18n.t('models.user.email'),
        validation: 'required',
        'validation-name': i18n.t('models.user.email'),
      },
      {
        type: 'text',
        name: 'firstName',
        label: i18n.t('models.user.firstName'),
        placeholder: i18n.t('models.user.firstName'),
        validation: 'required',
        'validation-name': i18n.t('models.user.firstName'),
      },
      {
        type: 'text',
        name: 'lastName',
        label: i18n.t('models.user.lastName'),
        placeholder: i18n.t('models.user.lastName'),
        validation: 'required',
        'validation-name': i18n.t('models.user.lastName'),
      },
    ],
  },
];
