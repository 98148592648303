











import Vue from 'vue';
import Component from 'vue-class-component';
// import LoadingButton from '@/components/shared/buttons/LoadingButton.vue';

import ErrorModal from '@/components/modals/ErrorModal.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';

// import TableRowSkeleton from '@/components/skeletons/TableRowSkeleton.vue';
// import { EventBus } from '../../plugins/event-bus';
// import formatMoney from '../../plugins/helpers';

// import services from '../../services';
// type loading = { close: () => void; changePercent: (num: any) => void };

@Component({
  components: {
    ErrorModal,
    SuccessModal,
    ConfirmModal,
    // LoadingButton,
    // TableRowSkeleton,
  },
})
export default class BaseComponent extends Vue {
  //   loading: loading;
  //   public services = services;
  //   public formatMoney(toFormat: number) {
  //     formatMoney(toFormat);
  //   }
  //   public showLoading() {
  //     this.loading.close();
  //   }
  //   public hideLoading() {
  //     this.loading.close();
  //   }
}
